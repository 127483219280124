const state = {
  geojson: [],
  geojsonZone: [],
  geojsonPoint: null,
  center: [3.1331173, -76.8244633],
  zoom: 3.5,
  markPoint: null,
  arrayPoint: [],
  markersService: [],
  tracingMarkersCliente: [],
  tracingMarkersCedi: [],
  tracingMarkersConductor: [],
  tracingStatusArray: [],
  geojsonRoute: [],
  visibleTracing: false,
  visibleTracingPoint: true,
  polylineArray: [],
  vehicle: null,
  date: null,
  tracingPoint: {
    lng: 0,
    lat: 0
  },
  tracingInfo: {},
  isResumen: false
}

export default state
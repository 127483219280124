const mutations = {
  SET_GEOJSON(state, geojson) {
    state.geojson = geojson
  },
  SET_GEOJSON_ZONE(state, geojsonZone) {
    state.geojsonZone = geojsonZone
  },
  SET_GEOJSONPOINT(state, geojsonPoint) {
    state.geojsonPoint = geojsonPoint
  },
  SET_CENTER (state, center) {
    state.center = center
  },
  SET_ZOOM (state, zoom) {
    state.zoom = zoom
  },
  SET_MARK_POINT (state, markPoint) {
    state.markPoint = markPoint
  },
  SET_ARRAY_POINT (state, arrayPoint) {
    state.arrayPoint = arrayPoint
  },
  SET_MARKER_SERVICE (state, markersService) {
    state.markersService = markersService
  },
  SET_TRACING_MARKERS_ARRAY (state, tracingMarkersCliente) {
    state.tracingMarkersCliente = tracingMarkersCliente
  },
  SET_TRACING_MARKERS_ARRAY_CEDI (state, tracingMarkersCedi) {
    state.tracingMarkersCedi = tracingMarkersCedi
  },
  SET_TRACING_MARKERS_ARRAY_CONDUCTOR (state, tracingMarkersConductor) {
    state.tracingMarkersConductor = tracingMarkersConductor
  },
  SET_SERVICES_STATUS_ARRAY (state, tracingStatusArray) {
    state.tracingStatusArray = tracingStatusArray
  },
  SET_GEOJSON_ROUTE (state, geojsonRoute) {
    state.geojsonRoute = geojsonRoute
  },
  SET_TRACINGPOINT(state, tracingPoint) {
    state.tracingPoint.lng = tracingPoint.lng
    state.tracingPoint.lat = tracingPoint.lat
    state.tracingInfo = tracingPoint
  },
  SET_VISIBLE_TRACING(state, visibleTracing) {
    state.visibleTracing = visibleTracing
  },
  SET_VISIBLE_TRACING_POINT(state, visibleTracingPoint) {
    state.visibleTracingPoint = visibleTracingPoint
  },
  SET_DATE(state, date) {
    state.date = date
  },
  SET_VEHICLE(state, vehicle) {
    state.vehicle = vehicle
  },
  SET_POLILINE_ARRAY (state, polylineArray) {
    state.polylineArray = polylineArray
  },
  SET_IS_RESUMEN(state, isResumen) {
    state.isResumen= isResumen
  }
}

export default mutations
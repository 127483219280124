import Vue from "vue"
import VxTooltip from "./layouts/components/vx-tooltip/VxTooltip.vue"
import VxCard from "./components/vx-card/VxCard.vue"
import VxList from "./components/vx-list/VxList.vue"
import VxBreadcrumb from "./layouts/components/VxBreadcrumb.vue"
import FeatherIcon from "./components/FeatherIcon.vue"
import VxInputGroup from "./components/vx-input-group/VxInputGroup.vue"
import vSelect from "vue-select"
import JsonExcel from "vue-json-excel"
import JsonCSV from 'vue-json-csv'
 
// import 'vue-select/dist/vue-select.css'

Vue.component(VxTooltip.name, VxTooltip)
Vue.component(VxCard.name, VxCard)
Vue.component(VxList.name, VxList)
Vue.component(VxBreadcrumb.name, VxBreadcrumb)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(VxInputGroup.name, VxInputGroup)
Vue.component('v-select', vSelect)
Vue.component('downloadExcel', JsonExcel)
Vue.component('downloadCsv', JsonCSV)
export default {
  actionIcon: 'StarIcon',
  highlightColor: 'warning',
  data: [
    {
      index: 0, 
      label: 'Inicio', 
      url: '/',
      labelIcon: 'HomeIcon',
      highlightAction: false
    },
    {
      index: 1,
      label: 'Rutas',
      url: '/routes',
      highlightAction: false
    }
  ]
}


import Vue from "vue"
import Router from "vue-router"
// import store from "@/store/store"

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/mapasp/FullPage.vue'),
      children: [{
        path: '/',
        props: true,
        component: () => import( /* webpackChunkName: "seguimiento" */ '@/views/mapsp/Index.vue'),
        beforeEnter: requiresAnonymous
      }],
    },
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/search',
          name: 'Login',
          component: () => import(/* webpackChunkName: "login" */ '@/components/seguimiento/FormSeach.vue')
        }
      ]
    },
    {
      path: '',
      component: () => import('@/layouts/main/Main.vue'),
      children: [{
        path: '/seguimiento',
        name: 'SPINDEX',
        props: true,
        component: () =>
          import( /* webpackChunkName: "seguimiento" */ '@/views/mapsp/Index.vue'),
        meta: {
          pageTitle: 'Map'
        }
      }],
    },
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ],
})


function requiresAnonymous(to, from, next) {
  return next({ name: 'Login' })
}

// function requiresAuth(to, from, next) {
//     if (store.getters['auth/isAuthenticated']) {
//         return next()
//     } else {
//         return next({ name: 'Login' })
//     }
// }

export default router
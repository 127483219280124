<template lang="pug">
  #app
    router-view
</template>

<script>
import themeConfig from "@/../themeConfig.js"
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState("auth", ["tokenFailed"]),
  },
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      isApplicationBlocked: false,
      isPopupActive: false,
      stepInfo: { status: "", message: "", progress: 0 },
      timeout: -1,
      isRetry: false
    }
  },
  watch: {
    "$i18n.locale"(val) {
      this.$validator.localize(val)
    },
    "$store.state.theme"(val) {
      this.toggleClassInBody(val)
    }
  },
  mounted() {
    let colorSchemeQueryList = window.matchMedia('(prefers-color-scheme: dark)');

    const setColorScheme = e => {
      if (e.matches) {
        // Dark
        this.$store.dispatch("updateTheme", "dark")
      } else {
        // Light
        this.$store.dispatch("updateTheme", "light")
      }
    }

    setColorScheme(colorSchemeQueryList);
    colorSchemeQueryList.addListener(setColorScheme);
    let val = null
    if (this.$store.state.language.language != null) {
      val = this.$store.state.language.language ? this.$store.state.language.language : this.$i18n.locale
      this.$store.dispatch("language/setLanguage", val)
    } else {
      val = this.$store.state.language.language ? this.$store.state.language.language : this.$i18n.locale
      this.$store.dispatch("language/setLanguage", val)
    }
    this.$i18n.locale = val
    this.toggleClassInBody(themeConfig.theme)
  },
  methods: {
    toggleClassInBody(className) {
      if (className == "dark") {
        if (document.body.className.match("theme-semi-dark")) document.body.classList.remove("theme-semi-dark")
        document.body.classList.add("theme-dark")
      } else if (className == "semi-dark") {
        if (document.body.className.match("theme-dark")) document.body.classList.remove("theme-dark")
        document.body.classList.add("theme-semi-dark")
      } else {
        if (document.body.className.match("theme-dark")) document.body.classList.remove("theme-dark")
        if (document.body.className.match("theme-semi-dark")) document.body.classList.remove("theme-semi-dark")
      }
    }
  }
}
</script>

<style lang="scss">
.con-vs-loading {
  z-index: 100000000 !important;
}
#blockApplicationPopup {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100000000;
  top: 0;
  background-color: #00000066;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.router-view {
  overflow-y: auto;
}
</style>
import Vue from "vue"
import * as Vue2Leaflet from "vue2-leaflet"
import { Icon } from "leaflet"
import 'leaflet-draw'
import "leaflet/dist/leaflet.css"
import "leaflet-draw/dist/leaflet.draw-src.css"

Vue.component('l-map', Vue2Leaflet.LMap)
Vue.component('l-tile-layer', Vue2Leaflet.LTileLayer)
Vue.component('l-marker', Vue2Leaflet.LMarker)
Vue.component('l-control-attribution', Vue2Leaflet.LControlAttribution)
Vue.component('l-control-scale', Vue2Leaflet.LControlScale)
Vue.component('l-geo-json', Vue2Leaflet.LGeoJson)
Vue.component('l-control-layers', Vue2Leaflet.LControlLayers)
Vue.component('l-control-zoom', Vue2Leaflet.LControlZoom)
Vue.component('l-tooltip', Vue2Leaflet.LTooltip)
Vue.component('l-popup', Vue2Leaflet.LPopup)
Vue.component('l-polyline', Vue2Leaflet.LPolyline)
Vue.component('l-polygon', Vue2Leaflet.LPolygon)

delete Icon.Default.prototype._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})
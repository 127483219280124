import Vue from "vue"
import moment from "moment"
import i18n from "@/i18n.js"

Vue.filter('capitalize', function(value) {
    if (!value) return ''
    var valuee = value.toString()
    return valuee.charAt(0).toUpperCase() + valuee.toLowerCase().slice(1)
})

Vue.filter('capitalizeAll', function(value) {
    if (!value) return ''
    var valuee = value.toString()
    let valueSplit = valuee.split('(')

    return valueSplit[0].charAt(0).toUpperCase() + valueSplit[0].toLowerCase().slice(1) + ' (' + valueSplit[1].charAt(0).toUpperCase() + valueSplit[1].toLowerCase().slice(1)
})

Vue.filter('dateformat', function(value) {
    if (value == null) return ''
    return moment(value, 'YYYY-MM-DD').format("DD/MM/YYYY")
})

Vue.filter('dateformatHour', function(value) {
    if (value == null) return ''
    return moment(value, 'YYYY-MM-DD HH:mm').format("LLLL")
})
Vue.filter('dateformatUser', function(value) {
    if (value == null) return ''
    return moment(value).format("DD/MM/YYYY HH:mm")
})

Vue.filter('filterPorcentaje', function(val) {
    var value = val.toFixed(2)
    return value
})

Vue.filter('filterInter', function(value) {
    return parseInt(value)
})

export function sortDataByKey(order, data, keyName) {
    let dataSort = []
    Object.assign(dataSort, data)
    dataSort.sort(
        function(base, compare) {
            if (order == 'descending') {
                return compare[keyName] - base[keyName]
            } else {
                return base[keyName] - compare[keyName]
            }
        }
    )
    return dataSort
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getMoneyFormat(number) {
    return `${number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
}

export function translateTableFields(fields, key, funtionsFields, additionalsFields) {
    // let configGrids = JSON.parse(localStorage.getItem("tableSettings"))[key]

    let camp = fields
    if (additionalsFields) {
        camp = camp.concat(additionalsFields)
    }
    return camp.map(field => {
        let dataField = {...field }
        dataField.translate = ''
        if (typeof(field.traslateName) === 'object') {
            dataField.translate = field.traslateName[i18n.locale]
        } else {
            dataField.translate = `${i18n.t(field.traslateName)}`
        }
        if (funtionsFields) {
            for (let val in funtionsFields) {
                if (val == field.field) {
                    let search = funtionsFields[val]
                    if (search) {
                        for (const funtionName in search) {
                            dataField[funtionName] = search[funtionName]
                        }
                    }
                }
            }
        }
        return dataField
    })
}

export function validateFilter({
    cedisFilter,
    vehiclesFilter,
    zonesFilter,
    objectStructure,
    moduleName
}) {
    let filters = {}
    if (moduleName === "cedi") {
        filters = cedisFilter
    } else if (moduleName === "vehicle") {
        filters = vehiclesFilter
    } else if (moduleName === "zone") {
        filters = zonesFilter
    }
    for (const filter in filters) {
        let found = false
        for (const zone in objectStructure) {
            if (moduleName === "zone" && filter === zone) {
                found = true
            } else {
                if (zonesFilter[zone]) {
                    let cedis = objectStructure[zone]
                    for (const cedi in cedis) {
                        if (moduleName === "cedi" && filter === cedi) {
                            found = true
                        } else {
                            if (cedisFilter[cedi]) {
                                let vehicles = cedis[cedi]
                                for (const vehicle in vehicles) {
                                    if (moduleName === "vehicle" && filter === vehicle) {
                                        found = true
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (!found) {
            delete filters[filter]
        }
    }
}
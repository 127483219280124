import Vue from "vue"
import VueSocketIO from "vue-socket.io"
import HTTP from "@/http-common"
HTTP.post('https://integracion.smartquick.com.co:3005/v1/services/ws/').then(function (response) {
  localStorage.setItem('TokenSocket', response.data.data)
})
const options = { autoConnect: false }
Vue.use(new VueSocketIO({
    debug: process.env.NODE_ENV !== 'production',
    connection: process.env.VUE_APP_URL_SOCKET,
    options: options
}))


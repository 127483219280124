const actions = {
  geojsonSet ({ commit }, val) {
    commit('SET_GEOJSON', val)
  },
  geojsonZoneSet ({ commit }, val) {
    commit('SET_GEOJSON_ZONE', val)
  },
  geoJsonPointSet ({ commit }, val) {
    commit('SET_GEOJSONPOINT', val)
  },
  geojsonRoute ({ commit }, val) {
    commit('SET_GEOJSON_ROUTE', val)
  },
  centerSet ({ commit }, val) {
    commit('SET_CENTER', val)
  },
  zoomSet({ commit }, val) {
    commit('SET_ZOOM', val)
  },
  markPoitnSet({ commit }, val) {
    commit('SET_MARK_POINT', val.pointMark)
  },
  markArrayPoint({ commit }, val) {
    commit('SET_ARRAY_POINT', val)
  },
  markersService({ commit }, val) {
    commit('SET_MARKER_SERVICE', val)
  },
  tracingMarkersCliente({ commit }, val) {
    commit('SET_TRACING_MARKERS_ARRAY', val)
  },
  tracingMarkersCedi({ commit }, val) {
    commit('SET_TRACING_MARKERS_ARRAY_CEDI', val)
  },
  tracingMarkersConductor({ commit }, val) {
    commit('SET_TRACING_MARKERS_ARRAY_CONDUCTOR', val)
  },
  tracingStatusArray({ commit }, val) {
    commit('SET_SERVICES_STATUS_ARRAY', val)
  },
  tracingPoint({ commit }, val) {
    commit('SET_TRACINGPOINT', val)
  },
  visibleTracing({ commit }, val) {
    commit('SET_VISIBLE_TRACING', val)
  },
  visibleTracingPoint({ commit }, val) {
    commit('SET_VISIBLE_TRACING_POINT', val)
  },
  VehicleDate({ commit }, val) {
    commit('SET_DATE', val.date)
    commit('SET_VEHICLE', val.vehicle)
  },
  setPolylineArray({ commit }, val) {
    commit('SET_POLILINE_ARRAY', val)
  }
}

export default actions


import navbarSearchAndPinList from '@/layouts/components/navbarSearchAndPinList'
import themeConfig from '@/../themeConfig.js'
import colors from '@/../themeConfig.js'
import JwtService from "@/jwt-service"

const state = {
    idRouter: null,
    isSidebarActive: true,
    breakpoint: null,
    sidebarWidth: "default",
    reduceButton: themeConfig.sidebarCollapsed,
    bodyOverlay: false,
    sidebarItemsMin: false,
    theme: themeConfig.theme || 'light',
    navbarSearchAndPinList: navbarSearchAndPinList,
    urlMapa: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    img: {
      img_cedi: require('@/assets/images/icon/CEDI.svg'),
      img_vehiculo: require('@/assets/images/icon/vehiculo.svg'),
      img_location: require('@/assets/images/icon/no_location.svg'),
      img_estado: require('@/assets/images/icon/estado_icono.svg'),
      img_rutear: require('@/assets/images/icon/rutear_active.png'),
      img_resumen: require('@/assets/images/icon/resumen_ruteo_active.png'),
      img_confirmar: require('@/assets/images/icon/confirmar_ruteo_active_opcion2.png'),
      img_search: require('@/assets/images/icon/_lupa.svg'),
      img_liquidator: require('@/assets/images/icon/liquidator.png'),
      img_refresh: require('@/assets/images/icon/_refresh.svg'),
      img_date_services: require('@/assets/images/icon/calendario_servicio.png'),
      img_zone: require('@/assets/images/icon/__zonificacion.png'),
      img_ver: require('@/assets/images/icon/ver_ruteo_active.png'),
      ciudad_active: require('@/assets/images/icon/ciudad_active.png'),
      departamento_active: require('@/assets/images/icon/departamento_active.png'),
      img_upload: require('@/assets/images/icon/upload_active.svg'),
      img_crear: require('@/assets/images/icon/crear_active.svg'),
      img_listar_services: require('@/assets/images/icon/listar_services.svg'),
      img_crear_depots: require('@/assets/images/icon/boton_crear_cedi.svg'),
      img_crear_cliente: require('@/assets/images/icon/clientes_active.svg'),
      img_showruteo: require('@/assets/images/icon/rutear_active.svg'),
      img_showruteo_segui: require('@/assets/images/icon/seguimiento_hist_ruteo.svg'),
    },
    httpOptions: {
      headers: {
        Authorization: `JWT ${JwtService.getToken()}`
      }
    },
    moduleGrids: [
      {
        id: 1,
        key: 'users',
        value: 'users'
      },
      {
        id: 2,
        key: 'service',
        value: 'service'
      }
    ],
    moduleTypes: [
      {
        id: 1,
        name: "users",
        slug: "users",
        value: "Usuario"
      },
      {
        id: 2,
        name: "vehicle",
        slug: "vehicle",
        value: "Vehículo"
      },
      {
        id: 3,
        name: "depot",
        slug: "depot",
        value: "CEDI"
      },
      {
        id: 4,
        name: "city",
        slug: "city",
        value: "Ciudad"
      },
      {
        id: 5,
        name: "client",
        slug: "client",
        value: "Cliente"
      },
      {
        id: 6,
        name: "newness",
        slug: "newness",
        value: "Novedades"
      },
      {
        id: 7,
        name: "product",
        slug: "product",
        value: "Productos"
      },
      {
        id: 8,
        name: "roles",
        slug: "roles",
        value: "Perfiles"
      },
      {
        id: 9,
        name: "service",
        slug: "service",
        value: "Servicios"
      }
    ],
    deviceTypes: [
      {
        id: 1,
        name: "Movil",
        slug: "Movil"
      },
      {
        id: 2,
        name: "GPS",
        slug: "GPS"
      }
    ],
    gender: [
      {
        id: 0,
        es: 'Masculino',
        en: 'Male'
      },
      {
        id: 1,
        es: 'Femenino',
        en: 'Female'
      }
    ],
    documentType: [
      {
        id: 0,
        name: 'citizenship_card',
        val: 'Cédula de Ciudadanía',
        en: 'Citizenship card',
        es: 'Cédula de Ciudadanía'
      },
      {
        id: 1,
        name: 'foreigner_id',
        val : 'Cédula de Extranjería',
        es: 'Cédula de Extranjería',
        en: 'Foreigner id'
      },
      {
        id: 2,
        name: 'passport',
        val: 'Pasaporte',
        es: 'Pasaporte',
        en: 'Passport'
      },
      {
        id: 3,
        name: 'nit',
        val: 'Nit',
        es: 'Nit',
        en: 'Nit'
      }
    ],

    themePrimaryColor: colors.primary,
    starredPages: navbarSearchAndPinList.data.filter((page) => page.highlightAction),
}

export default state
